import { TFilterLocations, TLocation, TLocationSeason } from 'shared/types/location.types';
import { TFiltersTours, TTour } from 'shared/types/tours.types';
import isContainArray from './isContainArray';

const monthNames = [
  'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
  'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
];

const seasonsNames = {
  winter: ['Декабрь', 'Январь', 'Февраль'],
  spring: ['Март', 'Апрель', 'Май'],
  summer: ['Июнь', 'Июль', 'Август'],
  autumn: ['Сентябрь', 'Октябрь', 'Ноябрь']
};

export const getSeasonForFilter = (seasonsLocation: TLocationSeason | null) => {
  if (!seasonsLocation) {
    return monthNames; // Если данные отсутствуют, возвращаем все месяцы.
  }

  let selectedMonths: string[] = [];

  if (seasonsLocation.winter) selectedMonths.push(...seasonsNames.winter);
  if (seasonsLocation.spring) selectedMonths.push(...seasonsNames.spring);
  if (seasonsLocation.summer) selectedMonths.push(...seasonsNames.summer);
  if (seasonsLocation.autumn) selectedMonths.push(...seasonsNames.autumn);

  return selectedMonths.length ? selectedMonths : Object.values(monthNames).flat();
};

export const filterForLocations = (item: TLocation, values: TFilterLocations) => {
  const seasonsLocation = getSeasonForFilter(item.season);

  if (values.types.length && !isContainArray(item.locationType, values.types)) return false;
  if (values.season.length && !isContainArray(seasonsLocation, values.season)) return false;
  if (values.territory.length && !values.territory.includes(item.cluster?.name || '')) return false;
  return !(values.transport.length && !isContainArray(item.availableTransfers, values.transport));

};

const getSeasonTourForFilter = (tour: TTour) => {
  if (!tour.months || tour.months.length === 0) {
    return Object.values(monthNames).flat();
  }

  return tour.months.map((month) => monthNames[month - 1]);
};


const getDurationForFilter = (values: TFiltersTours) => {
  const [minDays, maxDays] = values.duration.split(/[\s-]+/, 2);
  return { minDays, maxDays };
};

const checkTypeTour = (tour: TTour, filterTypes: string[]) => {
  const { tourTypeMulti, productType } = tour;
  return !filterTypes.length || isContainArray(tourTypeMulti, filterTypes) || filterTypes.includes(productType);
};

const checkLocationsTour = (tour: TTour, filterLocations: string[]) => {
  return !filterLocations.length || !!tour.locations?.find((location) => filterLocations.includes(location.name));
};

export const customFilterForTours = (tour: TTour, values: TFiltersTours) => {
  const { price, duration } = tour;
  const [minPrice, maxPrice] = values.price;
  const { minDays, maxDays } = getDurationForFilter(values);
  const seasonsTour = getSeasonTourForFilter(tour);
  if (values.duration !== 'Не выбрано' && !(duration >= Number(minDays) && duration <= Number(maxDays))) return false;
  if (!(price >= minPrice && price <= maxPrice)) return false;
  if (values.season.length && !isContainArray(seasonsTour, values.season)) return false;

  // Проверка по месяцам
  if (
    values.months.length &&
    !values.months.some((month) => (tour.months || []).includes(month))
  ) {
    return false;
  }

  if (values.types.length && values.locations.length) {
    return checkLocationsTour(tour, values.locations) || checkTypeTour(tour, values.types);
  } else if (values.types.length || values.locations.length) {
    return checkLocationsTour(tour, values.locations) && checkTypeTour(tour, values.types);
  }
  return true;
};
