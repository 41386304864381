import { useHistory } from 'react-router-dom';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import { declensionNoun } from 'helpers/declensionNoun';
import { TTour } from 'shared/types/tours.types';
import { ReactComponent as RubleIcon } from 'assets/images/common/ruble.svg';
import { getTextFromMinMaxValue } from 'helpers/getTextFromMinMaxValue';

type TTourCardProps = {
  tour: TTour;
  endSwipe?: () => void;
  isSwipe?: boolean;
};

export const TourCard = ({ tour, endSwipe, isSwipe }: TTourCardProps) => {
  const {
    minAge,
    maxAge,
    price,
    preview,
    productType,
    title,
    months,
    shortDesc,
    minGroupSize,
    maxGroupSize,
    partner,
    photo,
  } = tour;
  const priceText = price.toLocaleString('ru-RU');
  const ageText = getTextFromMinMaxValue(minAge, maxAge, { key: 'age' });
  const groupText = getTextFromMinMaxValue(minGroupSize, maxGroupSize, { label: 'чел' });

  const monthNames = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ];

  const allMonths = new Set(months);
  const isYearRound = allMonths.size === 12;
  const seasonText = isYearRound
    ? 'круглый год'
    : [...allMonths]
      .sort((a, b) => a - b)
      .reduce<string[]>((acc, month, index, array) => {
        const monthName = monthNames[month - 1];

        if (index > 0 && array[index] === array[index - 1] + 1) {
          const last = acc[acc.length - 1];
          const [start, end] = last.split('-');
          acc[acc.length - 1] = `${start}-${monthName}`;
        } else {
          acc.push(monthName);
        }

        return acc;
      }, [])
      .join(', '); // Склеиваем все элементы в строку


  const namePartner = partner?.name;
  const photoPartner = !!photo?.path ? `${URL_FOR_IMG}/${photo.path}` : null;

  const openTour = () => {
    if (!isSwipe) {
      const newTab = window.open(`/tours/${tour.engName}`, '_blank');
      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. It might be blocked by the browser.');
      }
    }
  };

  return (
    <div className='tour-card' key={tour.id} onClick={openTour} onMouseDown={endSwipe}>
      <div className='tour-card__content'>
        <div className='tour-card__preview'>
          <img
            className='tour-card__img'
            src={`${URL_FOR_IMG}/${preview && preview.path ? preview.path : null}`}
            alt={title}
          />
          {productType === TRANSFORM_TEXT.productType.EXCURSION && (
            <div className='tour-card__type'>{TRANSFORM_TEXT.productType.EXCURSION}</div>
          )}
          {productType === TRANSFORM_TEXT.productType.TOUR && (
            <div className='tour-card__type'>{TRANSFORM_TEXT.productType.TOUR}</div>
          )}
        </div>
        <div className='tour-card__description first'>
          <h5 className='tour-card__name'>{title}</h5>
          <div className='tour-card__params'>
            <div className='tour-card__params__data'>
              <div className='tour-card__params__name'>Месяцы</div>
              <div className='tour-card__params__value'>{seasonText}</div>
            </div>
            <div className='tour-card__params__data'>
              <div className='tour-card__params__name'>Группа</div>
              <div className='tour-card__params__value group'>{groupText}</div>
            </div>
            <div className='tour-card__params__data'>
              <div className='tour-card__params__name'>Возраст</div>
              <div className='tour-card__params__value age'>{ageText}</div>
            </div>
          </div>
          <p className='tour-card__text'>{shortDesc}</p>
        </div>
        <div className='tour-card__description second'>
          <div>
            <div className='tour-card__params__value price'>
              от {priceText} <RubleIcon className='ruble-icon' />
            </div>
            <div className='tour-card__params__value duration'>
              {`${tour.duration} ${declensionNoun(tour.duration, 'day')}`}
            </div>
          </div>
          <div className='tour-card__operator'>
            <div className='tour-card__operator-photo'>{!!photoPartner && <img src={photoPartner} alt='' />}</div>
            <div className='tour-card__operator-name'>{namePartner}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
