import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { clearFilters } from 'manageStore/partners/partners.slice';
import { selectPartners } from 'manageStore/partners/partners.select';
import { fetchPartners } from 'manageStore/partners/partners.thunk';
import Header from 'components/Header/Header';
import Footer from 'widgets/lib/Footer/Footer';
import { PartnersMain } from 'widgets/lib/partnersMain/PartnersMain/PartnersMain';
import { AuthPortal } from 'features/lib/AuthPortal/AuthPortal';

export const PartnersPage = () => {
  const dispatch = useDispatch();
  const partners = useSelector(selectPartners);

  useEffect(() => {
    if (!partners) {
      dispatch(fetchPartners());
    }
  }, [partners]);

  useEffect(() => {
    return () => {
      dispatch(clearFilters());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Организаторы путешествий и партнеры - Туркластер Арктический</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
      </Helmet>
      <Header isFixed />
      <AuthPortal />
      <PartnersMain />
      <Footer />
    </>
  );
};
